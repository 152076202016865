<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.223389 12.781V0.219025L9.29786 6.5L0.223389 12.781ZM10.7272 12.781V0.219025L19.8017 6.5L10.7272 12.781Z"
      fill="#6E19F0"
    />
  </svg>
</template>
<script>
export default {
  name: 'ic-fast-forward',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
    title: {
      type: String,
      default: 'FastForward',
    },
  },
}
</script>
